<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }"
            >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item  :to="{
        path: '/academic_Management/academic_font_management/academic_referee_system',
      }">裁判系统</el-breadcrumb-item>
            <el-breadcrumb-item
            >全国总决选复活赛</el-breadcrumb-item
        >
    </el-breadcrumb>
    <iframe :src="iframeUrl" frameborder="0" style="width: 100%; height: calc(100vh - 128px);"></iframe>
  </div>
</template>

<script>
export default {
  data () {
    return {
      getInfo: false,
      prefixUrl: process.env.NODE_ENV === 'production' ? 'https://erp.spbcn.org/zhangtr/V3/views/home/index.html' : 'https://test.erp.spbcn.org/zhangtr/V3/views/home/index.html',
      iframeUrl: ''
    }
  },
  created () {
    this.getMatchInfo()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    getMatchInfo () {
      console.log('prefixUrl', this.prefixUrl)
      const token = localStorage.getItem('Authorization')
      const activityType = this.$chnEngStatusCode.activityType
      this.iframeUrl = `${this.prefixUrl}?token=${token}&activityType=${activityType}&raceSchedule=7&reset=1`
    }
  }
}
</script>

<style lang="less" scoped>
</style>
